import "core-js/modules/es6.object.assign";
import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import ViewportLock from "../../../packages/ViewportLock/src";
import Div from "../../../packages/Div/src";
import Flex from "../../../packages/Flex/src";
import Icon from "../../../packages/Icon/src";
import Button from "../../../packages/Button/src";
import Container from "../../../packages/Container/src";
import ButtonGroup from "../../../packages/ButtonGroup/src";

var ImmersiveStoryToolbarPattern = function ImmersiveStoryToolbarPattern() {
  return React.createElement(PatternLayout, {
    minimal: true,
    navColor: "white",
    logoOutlined: true
  }, React.createElement(ViewportLock, {
    bg: "grey.900",
    color: "white"
  }, React.createElement(Flex, {
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  }, React.createElement(Div, {
    textAlign: "center"
  }, "Content")), React.createElement(Div, {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    bg: "white",
    boxShadow: "md",
    color: "text"
  }, React.createElement(Container, {
    size: "xs",
    py: "0.75rem"
  }, React.createElement(ButtonGroup, {
    onChange: function onChange(btn) {
      return console.log("selected: " + btn.label);
    },
    width: "100%"
  }, function (selected, getItemProps) {
    return React.createElement(React.Fragment, null, [{
      key: 1,
      iconName: "align_left",
      label: "Align left"
    }, {
      key: 2,
      iconName: "align_center",
      label: "Align center"
    }, {
      key: 3,
      iconName: "align_right",
      label: "Align right"
    }].map(function (item) {
      var isSelected = item.key === selected.key;
      return React.createElement(Button, Object.assign({
        key: item.key,
        variant: isSelected ? "primary" : "subtle",
        style: {
          flexGrow: "1"
        },
        borderRadius: "md"
      }, getItemProps(item)), React.createElement(Icon, {
        name: item.iconName,
        size: "1.25rem"
      }));
    }));
  })))));
};

export default ImmersiveStoryToolbarPattern;